<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <trac-modal v-if="successModalCtrl" :cancel="false">
      <div class="flex flex-col justify-center items-center p-6 gap-6">
        <img
          src="./../../../assets/svg/pin-management/success-icon.svg"
          alt=""
        />
        <p class="text-sm">{{ successRes.message }}</p>

        <button
          class="border-none bg-none text-primaryBlue p-4 outline-none"
          @click="confirmSuccessPref"
        >
          Done
        </button>
      </div>
    </trac-modal>

    <trac-back-button>Back</trac-back-button>

    <h3 class="my-8 font-bold text-sm">KYC(Settlement Preference)</h3>
    <div class="shadow-lg rounded-md p-4 pt-8 w-9/12 mx-auto my-8 px-12 py-16">
      <div class="w-5/12">
        <img
          src="./../../../assets/svg/pin-management/settlement-pref.svg"
          alt=""
          class="bg-accentLight p-5 rounded-md"
        />

        <h3 class="my-3 font-bold text-primaryBlue">
          How do you want to be settled?
        </h3>
        <p class="text-xs">Select how you would want to be settled.</p>

        <div
          class="
            mt-12
            mb-5
            flex
            justify-between
            border-b
            pb-2
            items-center
            relative
            info-hold
          "
        >
          <div class="flex items-center gap-5">
            <input
              type="radio"
              value="sameday"
              v-model="settlementType"
              name=""
              id="end-of-day"
            />
            <label for="end-of-day" class="text-xs font-semibold cursor-pointer"
              >End of the day settlement</label
            >
          </div>
          <img
            src="./../../../assets/svg/pin-management/info-icon.svg"
            alt=""
            class="info-icon"
          />
          <div
            class="
              absolute
              p-2
              rounded-md
              bg-backgroundArea
              w-60
              shadow-sm
              info-card
            "
          >
            <p class="" style="">
              At the end of each day, you will receive a settlement for all your
              transactions carried out for the day before 10pm sent to your
              settlement account.
            </p>
          </div>
        </div>

        <div
          class="
            mt-8
            mb-5
            flex
            justify-between
            border-b
            pb-2
            items-center
            relative
            info-hold
          "
        >
          <div class="flex items-center gap-5">
            <input
              type="radio"
              value="instant"
              v-model="settlementType"
              name=""
              id="instant-settlement"
            />
            <label
              for="instant-settlement"
              class="text-xs font-semibold cursor-pointer"
              >Instant settlement</label
            >
          </div>
          <img
            src="./../../../assets/svg/pin-management/info-icon.svg"
            alt=""
            class="info-icon"
          />
          <div
            class="
              absolute
              p-2
              rounded-md
              bg-backgroundArea
              w-60
              shadow-sm
              info-card
            "
          >
            <p class="" style="">
              Get instant settlement of all your transactions immediately the
              transaction is carried out, into your wallet account.
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-10">
        <trac-button @button-clicked="updateSettlementOption"
          >CONFIRM</trac-button
        >
      </div>
    </div>
  </main>
</template>

<script>
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      successModalCtrl: false,
      successRes: null,
      isLoading: false,
      settlementType: "",
    };
  },
  async created() {
    this.isLoading = true;
    const res = await this.$store.dispatch("FETCH_SETTLEMENT_TYPE");
    this.settlementType = res.data;
    this.isLoading = false;
  },
  methods: {
    confirmSuccessPref() {
      this.successModalCtrl = false;
      this.$router.replace({
        name: this.$route.query.onboarding ? "MainWallet" : "SettlementPreference",
      });
    },
    async updateSettlementOption() {
      this.isLoading = true;
      const res = await this.$store.dispatch("UPDATE_SETTLEMENT_TYPE", {
        settlement_type: this.settlementType,
      });
      if (res.status) {
        this.successModalCtrl = true;
        this.successRes = res;
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-hold {
  .info-icon {
    &:hover + .info-card {
      display: block;
    }
  }

  .info-card {
    top: 0%;
    font-size: 0.65rem;
    left: 105%;
    display: none;
  }
}
</style>